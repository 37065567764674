.nav {
    text-align: center;
    direction: rtl;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.line {
    text-align: center;
    direction: rtl;
}
.ui-tiny-images{
    size: 20px; 
    border-radius: 10%;
}
/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
    border-radius: 50%;
    background-color: #ffffff;
    width: 200px;
    height: 200px;
    /* border: 1px solid #ffffff; */
     perspective: 1000px; /*Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-box-inner {
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    border-radius: "50%";
    display: block;
  color: red;
    transform: rotateZ(360deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-box-front {
    border-radius: 50%;
    background-color: #ffffff;
    color: black;
  }
  
  /* Style the back side */
  .flip-box-back {
    display: none;
    border-radius: 50%;
    background-color: rgb(3, 85, 59);
    color: rgb(205, 50, 50);
    transform: rotateY(180deg);
  }
  
.all{
   display: flex;
   flex-wrap: wrap;
   flex-direction: row-reverse;
   justify-content: space-between;
}




/* Style the navigation menu */
/* .navbar {
  width: 100%;
  background-color: #c21212;
  overflow: auto;
} */

/* Navigation links */
/* .navbar a {
  float: left;
  padding: 12px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 17px;
  width: 11.111111111111111111111111111111%; /* Four equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  /* text-align: center; If you want the text to be centered */
/* } */ 

/* Add a background color on mouse-over
.navbar a:hover {
  background-color: #ce6460;
}

/* Style the current/active link */
/* .navbar a:active {
  background-color: #7fde19;
}
.navbar a::selection {
  background-color: #de5151;
} */ 

/* Add responsiveness - on screens less than 500px, make the navigation links appear on top of each other, instead of next to each other */
/* @media screen and (max-width: 500px) {
  .navbar a {
    float: none;
    display: block;
    width: 100%;
    text-align: left; /* If you want the text to be left-aligned on small screens */
  /* }
}  */




/* Style the navbar */
#navbar {
  overflow: auto;
  background-color: #333;
  position: static;
  position: -webkit-sticky;
  top: 0;
}

#navbar a:hover {
   background-color: #323930;  
  border-bottom: inset #97c052;
  color: #97c052;
  transition: 0.2s;

  
}
#navbar a:active {
  background-color: #9f9291;
}

.a.active{
  background-color: #877474;

}
.activeLink{
  background-color: #ac2525;
 }
 .navbar.scrolling-navbar.top-nav-collapse {
  background: #ff0000;
}
/* Navbar links */
#navbar a {
  display: block;
  color: #f2f2f2;
  text-align: justify;
  justify-content: center;
  float: left;
  padding: 14px 20px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  width: 11.111111111111111111111111111111%; /* 9 equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  text-align: center;
  text-decoration: none;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}
/* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 700px) {
  .row {   
    flex-direction: column;
  }
}

/* Responsive layout - when the screen is less than 400px wide, make the navigation links stack on top of each other instead of next to each other */
@media screen and (max-width: 750px) {
  #navbar a {
    float: none;
    width: 100%;
  }
}