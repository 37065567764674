.formStyle {
  direction: rtl;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.right {
  -ms-flex: 30%;
  /* IE10 */
  flex: 2;
  background-color: #333;
  padding: 10px;
  margin: 3% 0% 5%;


}

.middle {
  -ms-flex: 25%;
  /* IE10 */
  flex: 3;
  background-color: #333;
  padding: 35px;

}

.left {
  -ms-flex: 55%;
  /* IE10 */
  flex: 4;
  background-color: #333;
  padding: 0px;
  align-items: baseline;
}

.row {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: nowrap;
  direction: rtl;
  background-color: #333;

  max-width: 1500px;
}

.all {
  max-width: 1400px;
  margin: 40px auto 0 auto;
  display: flex;
  justify-content: space-between;
  direction: rtl;
  color: white;
}

.each {
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 30%;
margin-left: auto;
margin-right: auto;
}

@media screen and (max-width: 1046px) {
  .each{
    width: 25%;
   
  }
  .each-last{
    width: 40%;
  }

}


@media screen and (max-width: 700px) {
  /* .all{
    flex-direction: column;
    max-width: 100%;
  } */
  .each{
    width: 47.5%;
  }
  .each-second{
    margin: 0;
  }
  .each-last{
    clear: both;
    float: none;
    width: auto;
  }

}



/* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}