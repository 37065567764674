.form{
    
    border: 5px rgb(14, 108, 107) solid;
    align-content: center;
    justify-content: center;

    text-align: center;
    direction: rtl;
    color: teal;
    width: min-content;
    background-color: aquamarine ;
}
.addButton{
    background-color: cornflowerblue;
    color:aqua;
    
}
.rad{
    direction: ltr;
}