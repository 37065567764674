.st{
    align-content: center;
    text-align: center;
    direction: rtl;
    border: 2px solid black;
    font-size: large;
    color: aliceblue;
    min-height: 10vh;
    background-position: 20px;
    padding: 50px;
}
.logo{
    -ms-flex:20%; /* IE10 */
    flex: 20%;
    /* padding: 20px; */
}
/* .write{
    -ms-flex: 70%; /* IE10 */
  /* flex: 70%; */
  /* padding: 20px; */
  /* color: aliceblue; */
  /* justify-content: center; */
   /* direction:rtl; */
/* } */ 
.right{
  -ms-flex: 35%; /* IE10 */
  flex: 1;
  padding: 20px;
  display: block;
  text-align: justify;
  justify-content: center;
  float: left;
  padding: 14px 20px;
  width: 30%; /* 9 equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  text-align: center;
  text-decoration: none;

}

.left{
    -ms-flex: 65%; /* IE10 */
    flex: 3;
    padding: 20px;
    /* align-items: center; */
    display: block;
  text-align: justify;
  justify-content: center;
  float: left;
  padding: 14px 20px;
  width: 33.33333333333%; /* 9 equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  text-align: center;
  text-decoration: none;
}
.row {  
    display: -ms-flexbox; /* IE10 */
    display: flex;
    flex-flow: row;
    /* -ms-flex-wrap: wrap; /* IE10 */
    /* flex-wrap: wrap;  */
    direction: rtl;
   
  }
  
