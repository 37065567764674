.App {
  text-align: center;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a {
  text-decoration: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.style{
  /* background-image:url(/public/p3.jpg); */
  background-size: contain;
  background-color:azure;
  size: 1000px;
}

.di{
  direction: rtl;
  font-size: large;
}
/* Column container */
.row {  
  display: -ms-flexbox; /* IE10 */
  display: flex;
  flex-direction: row;
  width: 100%;

}

/* Create two unequal columns that sits next to each other */
/* Sidebar/left column */
.side {
  -ms-flex: 30%; /* IE10 */
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 0%;
}

/* Main column */
.main {   
  -ms-flex: 70%; /* IE10 */
  flex: 4;
  background-color: white;
  /* padding: 20px; */
  max-width: 100%;
}

/* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1000px) {
.row {    
     flex-direction: column;
     float: none;
    width: 100%; 
  }
  /* .side{
    flex: auto;
  }
  .main{
    flex: auto;
  } */
}

/* Responsive layout - when the screen is less than 400px wide, make the navigation links stack on top of each other instead of next to each other */
@media screen and (max-width: 400px) {
  .navbar a {
    float: none;
    width: 100%;
  }
}

Button:hover{
 opacity:0.7;
 box-shadow:1px 1px 10px gray;
 transition:0.3s;

}